import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import MetaWp from "../components/meta-wp"
import Layout from "../components/layout"
import PageBlock from "../components/page-block"
import ContentGrid from "../components/content-grid"

const DefaultPostListing = ({ data }) => {
  const {
    wp,
    allPosts,
    metaWp,
    site: { siteMetadata },
    wordpressAcfOptions: { globalMeta },
  } = data

  const {
    acf: { hideContent },
  } = wp

  return (
    <Layout>
      <MetaWp {...metaWp} global={globalMeta} defaults={siteMetadata} />

      {hideContent && (
        <h1
          className={`is-vhidden`}
          dangerouslySetInnerHTML={{ __html: wp.title }}
        />
      )}

      {!hideContent && <PageBlock {...wp} />}

      <ContentGrid
        published={true}
        type={`listing`}
        contentType={[`post`]}
        allPages={{}}
        allPosts={allPosts}
        categories={[]}
        numberOfItems={`all`}
        title={null}
        subTitle={``}
        backgroundColor={`white`}
        tags={[]}
        showCategories={true}
        showExcerpts={true}
        showReadMore={true}
        showImages={true}
        showTitles={true}
        sort={`published_asc`}
      />
    </Layout>
  )
}

DefaultPostListing.propTypes = {
  data: PropTypes.object.isRequired,
}

export default DefaultPostListing

export const pageQuery = graphql`
  query($id: String!) {
    site {
      ...SiteMetaData
    }

    ...MetaWpPage

    wordpressAcfOptions {
      ...GlobalMetaOptions
    }

    ...AllPostsFragment

    wp: wordpressPage(id: { eq: $id }) {
      ...PageDefaultFragment
    }
  }
`
