import React from "react"
import PropTypes from "prop-types"
import { rem } from "polished"
import styled from "styled-components"
import Img from "gatsby-image"

const Container = styled.section`
  ${(props) => props.theme.gridContainer()};

  color: ${(props) => props.theme.colorBlueDark};
`

const Inner = styled.div`
  max-width: ${rem(768)};
  padding-top: ${rem(60)};
  padding-bottom: ${rem(60)};

  @media ${(props) => props.theme.smallDown} {
    padding-top: ${rem(30)};
    padding-bottom: ${rem(30)};
  }
`

const Image = styled(Img)`
  margin-top: ${rem(20)};

  @media ${(props) => props.theme.mediumDown} {
    margin-top: ${rem(10)};
  }
`

const Introduction = styled.div.attrs({ className: `styled` })`
  margin-top: ${rem(20)};

  @media ${(props) => props.theme.mediumDown} {
    margin-top: ${rem(10)};
  }

  > p {
    font-size: ${rem(24)} !important;
    line-height: 1.5;

    @media ${(props) => props.theme.xxlargeDown} {
      font-size: ${rem(22)} !important;
    }
    @media ${(props) => props.theme.mediumDown} {
      font-size: ${rem(20)} !important;
    }
    @media ${(props) => props.theme.smallDown} {
      font-size: ${rem(18)} !important;
    }
  }
`

const Content = styled.div.attrs({ className: `styled` })`
  margin-top: ${rem(24)};
`

const PageBlock = ({ title, acf: { intro, body, image } }) => {
  return (
    <Container>
      <Inner>
        <h1
          className={`styled-h1`}
          dangerouslySetInnerHTML={{ __html: title }}
        />

        {image && <Image fluid={image.localFile.childImageSharp.fluid} />}

        {intro && <Introduction dangerouslySetInnerHTML={{ __html: intro }} />}

        {body && <Content dangerouslySetInnerHTML={{ __html: body }} />}
      </Inner>
    </Container>
  )
}

PageBlock.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  acf: PropTypes.object,
}

export default PageBlock
